import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WrapperHorizons from "../components/WrapperHorizons"

const One = () => (
  <Layout>
    <SEO title="horizons" />
    <WrapperHorizons />
  </Layout>
)

export default One
